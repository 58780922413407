/* DataGridStyles.css */

.admin-data-grid {
    background-color: #f0f0f0; /* Set your desired background color */
    color: #333; /* Set your desired text color */
  }
  
  .admin-data-grid-header {
    background-color: #4CAF50; /* Set your desired header background color */
    color: #fff; /* Set your desired header text color */
  }
  