.login-button {
    margin-right: 50px; /* Margin for large screens */
  }
  
  @media (max-width: 767px) {
    .login-button {
      margin-right: 0; /* Adjusted margin for smaller screens */
      margin-top: 8px;
      
    }
  }
  /* @media (max-width: 767px) {
    .text-welcome-page {
      margin-top: 20px;
      
    }
  } */


  @media (min-width: 768px) and (max-width: 3000px) {

    .link-role {
      margin-right: 5px;
    }
  }

  .responsive-img{
    width: 600px;
    height: 374;
  }
  @media (max-width: 768px) {
    .responsive-img {
      max-width: 100%;
      height: auto;
    }
  }
  
  .parent-img-h {
    height: 85%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .parent-img-h {
     margin: '20px';
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 85%;
    }
  }
  