.timer-container {
  text-align: center;
}

.timer-label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.timer {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

button {
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 800px) {
  .timer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .btn-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 7px !important;
  }
}
