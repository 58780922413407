/* .container-fluid {
    display: flex;
  }
  

.active{
  background-color: rgba(252, 250, 248, 0)!important;
  border-bottom: 1px solid rgba(255, 245, 238, 0.903) !important;
border-radius: 0px !important;
  
}
 .nav-item span{
    color: white;
  }
  .active span{
    
    color: #fdfcfc;
    font-size: x-large !important;
   font-weight: bolder;
   }
  .sticky-sidebar {
    position: fixed;
    top: 0;
    width: 25%;
    height: 100vh;
  }
  
  .content {
    flex: 1;
    font-weight: bolder;
  }
  .sidebar-body{
     background-color:#4F9F9F !important;
  }

  .sidebar-span{
    color: white !important;
 }
 
 .icons{
    color: #fdfdfd;
 }
 */
 .container-fluid {
  display: flex;
}


.active{
background-color: rgba(252, 250, 248, 0)!important;
border-bottom: 1px solid rgba(255, 245, 238, 0.903) !important;
border-radius: 0px !important;

}
.nav-item span{
  color: white;
  font-size: calc(1rem + 0.5vw);
}
.active span{
  
  color: #fdfcfc;
  /* font-size: x-large !important; */
  font-size: calc(1rem + 0.5vw);
 font-weight: bolder;
 }
.sticky-sidebar {
  position: fixed;
  top: 0;
  width: 25%;
  height: 100vh;
}

.content {
  flex: 1;
  font-weight: bolder;
}
.sidebar-body{
   background-color:#4F9F9F !important;
}

.sidebar-span{
  color: white !important;
}

.icons{
  color: #fdfdfd;
}