h5{
    font-size: 1.3rem;
}

@media (max-width: 768px) {
    /* Define styles for screens up to 768 pixels wide */
    h5 {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    /* Define styles for screens between 769px and 992px wide */
    h5 {
      font-size: 1.2rem;
    }
  }