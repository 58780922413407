.image-body{
    width: 500px;
    height: 500px;
}
.secondary-body{
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}
.no-outline:focus {
    outline: none !important;
    outline-style: none !important;
    box-shadow: none !important;
  }

  @media (max-width: 768px) {
    .image-body{
        width: 200px;
        height: 200px;
    }
    .secondary-body{
     margin: '20px';
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      
    }
  }