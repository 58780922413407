.container {
  background-color: white;
}

.container h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "montserrat";
}

.bg-second {
  background-color: #ecf3f4;
}
